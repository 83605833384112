import { BIRTHSTONE_LOADING } from "./actions";

const initialState = {
	certificate: false,
	birthStone: false,
	contact: false,
	birthStoneLoader: false
};

export default (state = initialState, action) => {
	switch (action.type) {
		case "GET_CERTIFICATE_SUCCESS":
			// console.log(action)
			return {
				...state,
				certificate: action.payload
			};
		case "GET_BIRTHSTONE_SUCCESS":
			// console.log(action)
			return {
				...state,
				birthStone: action.payload
			};
		case "POST_CONTACT_SUCCESS":
			// console.log(action)
			return {
				...state,
				contact: action.payload
			};
		case BIRTHSTONE_LOADING:
			// console.log(action)
			return {
				...state,
				birthStoneLoader: action.payload
			};
		default:
			return state;
	}
};

import { ConnectedRouter } from "connected-react-router";
import React, { lazy, Suspense } from "react";
import { hydrate, render } from "react-dom";
import { Provider } from "react-redux";
import "./bootstap.min.css";
import store, { history } from "./store";

import { HelmetProvider } from "react-helmet-async";
import "./index.scss";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const App = lazy(() => import("./containers/app"));

const target = document.querySelector("#root");
require("dotenv").config();

const Loader = () => {
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        alignContent: "center",
        justifyContent: "center",
      }}
    >
      <div className="loaderDiv">
        <div>
          <img
            alt="GGI-logo"
            width="250px"
            src={
              "https://res.cloudinary.com/ggi/image/upload/v1637903431/logo_ku2fnp.webp"
            }
          />
          <h1>GALAXY GEMOLOGICAL INSTITUTE</h1>
        </div>
      </div>
    </div>
  );
};
if (target.hasChildNodes()) {
  hydrate(
    <Suspense fallback={Loader()}>
      <HelmetProvider>
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <div>
              <App />
            </div>
          </ConnectedRouter>
        </Provider>
      </HelmetProvider>
    </Suspense>,
    target
  );
} else {
  render(
    <Suspense fallback={Loader()}>
      <HelmetProvider>
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <div>
              <App />
            </div>
          </ConnectedRouter>
        </Provider>
      </HelmetProvider>
    </Suspense>,
    target
  );
}
serviceWorkerRegistration.register();

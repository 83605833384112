import axios from "axios";

// product loader true

function certificateLoader() {
  return (dispatch) => {
    dispatch(cerificateLoading());
  };
}
export const CERTIFICATE_LOADING = "CERTIFICATE_LOADING";
export const cerificateLoading = (products) => {
  return {
    type: CERTIFICATE_LOADING,
    payload: {},
  };
};

// get skincare products all
export function getCertificate(entryNumber) {
  return (dispatch) => {
    dispatch(certificateLoader());
    var config = {
      method: "get",
      url: `/getSeprateReportsWeb/?searchText=${entryNumber}`,
      headers: {},
    };

    axios(config)
      .then(function (response) {
        // console.log(response.data);
        dispatch(getCertificateSuccess(response.data));
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });

    // return {}
  };
}
export const GET_CERTIFICATE_SUCCESS = "GET_CERTIFICATE_SUCCESS";
export const getCertificateSuccess = (data) => ({
  // console.log()
  type: GET_CERTIFICATE_SUCCESS,
  payload: data,
});

// Find Birth Stone

export const BIRTHSTONE_LOADING = "BIRTHSTONE_LOADING";
export const birthStoneLoading = (bool) => {
  return {
    type: BIRTHSTONE_LOADING,
    payload: bool,
  };
};



export function getBirthStone(data,clearState) {
  return (dispatch) => {
    dispatch(birthStoneLoading(true));
    const body = data;

    console.log(body);
    var config = {
      method: "POST",
      url: `/stoneFindSendMessage/`,
      data: body,
      headers: {},
    };
    console.log(config);
    axios(config)
      .then(function (response) {
    dispatch(birthStoneLoading(false));
    // console.log(response.data);
    clearState()
        dispatch(getBirthStoneSuccess(response.data));
        return response.data;
      })
      .catch(function (error) {
    dispatch(birthStoneLoading(false));
    console.log(error);
      });

    return {};
  };
}
export const GET_BIRTHSTONE_SUCCESS = "GET_BIRTHSTONE_SUCCESS";
export const getBirthStoneSuccess = (data) => ({
  // console.log()
  type: GET_BIRTHSTONE_SUCCESS,
  payload: data,
});

// Send Message

export const CONTACT_LOADING = "CONTACT_LOADING";
export const ContactLoading = (products) => {
  return {
    type: CONTACT_LOADING,
    payload: {},
  };
};

function ContactLoader() {
  return (dispatch) => {
    dispatch(ContactLoading());
  };
}
// get skincare products all
// export function getCertificate(entryNumber) {
//     return dispatch => {
//         dispatch(certificateLoader())
//         var config = {
//             method: 'get',
//             url: `https://beckendchecking.softsyncdev.work/getSeprateReportsWeb/?searchText=${entryNumber}`,
//             headers: {}
//         };

//         axios(config)
//             .then(function (response) {
//                 // console.log(response.data);
//                 dispatch(getCertificateSuccess(response.data));
//                 return (response.data)
//             })
//             .catch(function (error) {
//                 console.log(error);
//             });

//         // return {}
//     };
// }

export function postContact(obj) {
  return (dispatch) => {
    dispatch(ContactLoader());
    let data = JSON.stringify(obj);
    var config = {
      method: "post",
      url: "/sendMessage/",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        dispatch(postContactSuccess(response.data));
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };
}
export const POST_CONTACT_SUCCESS = "POST_CONTACT_SUCCESS";
export const postContactSuccess = (data) => ({
  // console.log()
  type: POST_CONTACT_SUCCESS,
  payload: data,
});
export const dateFunction = (a, b) => {
  if (a !== "0000-00-00") {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const d = new Date(a);
    let month = monthNames[d.getMonth()];
    let date = d.getDate();
    let year = d.getFullYear();

    return `${date} ${month} ${year}`;
  } else {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const d = new Date(b);
    let month = monthNames[d.getMonth()];
    let date = d.getDate();
    let year = d.getFullYear();

    return `${date} ${month} ${year}`;
  }
};
export const dateFunctionB = (a) => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const d = new Date(a);
  let month = monthNames[d.getMonth()];
  let date = d.getDate();
  let year = d.getFullYear();
  return `${date} ${month !== undefined ? month.slice(0, 3) : ""} ${year}`;
};
